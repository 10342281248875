<template>
  <div>
    <el-data-table ref="table" v-bind="tableConfig" :columns="columns">
    </el-data-table>

    <el-dialog :title="title" :visible.sync="dialogFormVisible" :close-on-press-escape="false" :close-on-click-modal="false"  :before-close="closeDialog">
      <el-form :model="form" :rules="rules" ref="ruleUser">
            <el-form-item label='序号' prop='id' :label-width="formLabelWidth" v-if="false">
              <el-input placeholder='请输入序号' clearable autocomplete="off" v-model='form.id' size="small"  />
            </el-form-item>
            <el-form-item label='用户邮箱' prop='email' :label-width="formLabelWidth">
                <el-input placeholder='请输入用户邮箱' clearable autocomplete="off" v-model='form.email' style="width: 300px;"/>
            </el-form-item>
            <el-form-item label='折扣码' prop='discountcode' :label-width="formLabelWidth">
                <el-input placeholder="请输入折扣码" v-model="form.discountcode" class="input-with-select" style="width: 350px;">
                    <el-button slot="append" style="color:#409EFF" @click="getRandomNum">生成折扣码</el-button>
                </el-input>
            </el-form-item>
            <el-form-item label='折扣比例' prop='discountratio' :label-width="formLabelWidth">
                <el-input-number placeholder='请输入折扣比例' clearable autocomplete="off" v-model='form.discountratio' :precision="2" :step="0.1" :min="0.1" :max="0.99"></el-input-number>
            </el-form-item>
            <el-form-item label='折扣类型' prop='type' :label-width="formLabelWidth">
                <el-select v-model="form.type" placeholder="请选择" @change="clearRules">
                    <el-option
                            v-for="item in typeOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label='折扣规则' prop='rules' :label-width="formLabelWidth" v-if="form.type=='2'">
                <template>
                    <el-table ref="tablerules" border :data="form.rules" style="width: 100%">
                        <el-table-column label="序号" align="center" width="55" prop="xh" type="index"></el-table-column>
                        <el-table-column prop="goodsid" label="商品名称">
                            <template slot-scope="scope">
                                <el-select v-model="scope.row.goodsid" placeholder="请选择" @change="getGoodsSkuListInfo(scope.row.goodsid)">
                                    <el-option
                                            v-for="item in goodsList"
                                            :key="item.id"
                                            :label="item.goodsname"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </template>
                        </el-table-column>
                        <el-table-column prop="skuid" label="套餐名称">
                            <template slot-scope="scope">
                                <el-select v-model="scope.row.skuid" placeholder="请选择">
                                    <el-option
                                            v-for="item in skuList"
                                            :key="item.id"
                                            :label="item.skuname"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </template>
                        </el-table-column>
                        <el-table-column prop="discountratio" label="折扣比例" width="205">
                            <template slot-scope="scope">
                                <el-input-number placeholder='请输入折扣比例' clearable autocomplete="off" v-model='scope.row.discountratio' :precision="2" :step="0.1" :min="0.1" :max="0.99"></el-input-number>
                            </template>
                        </el-table-column>
                        <el-table-column fixed="right" label="操作">
                            <template slot-scope="scope">
                                <el-button @click.native.prevent="applaydeleteRow(scope.$index, form.rules)" size="small">
                                    移除
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-row style="border:0;">
                        <el-col style="text-align:center;" >
                            <el-button type="primary" style="width:100px; position: relative;bottom:2px;margin-top: 10px;"  @click="applayaddRow" >+</el-button>
                        </el-col>
                    </el-row>
                </template>
            </el-form-item>
            <el-form-item label='权重' prop='weight' :label-width="formLabelWidth">
              <el-input-number placeholder='请输入权重' clearable autocomplete="off" v-model='form.weight' :precision="0" :step="1" :min="0"></el-input-number>
            </el-form-item>
<!--            <el-form-item label='操作人id' prop='operatorid' :label-width="formLabelWidth">-->
<!--              <el-input placeholder='请输入操作人id' clearable autocomplete="off" v-model='form.operatorid' size="small"  />-->
<!--            </el-form-item>-->
<!--            <el-form-item label='操作人' prop='operator' :label-width="formLabelWidth">-->
<!--              <el-input placeholder='请输入操作人' clearable autocomplete="off" v-model='form.operator' size="small"  />-->
<!--            </el-form-item>-->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog()">取 消</el-button>
        <el-button type="primary" @click="submitUser">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { insertDiscountcode,updateDiscountcode,deleteDiscountcode,bacthDelDiscountcode,getGoodsSpuList,getGoodsSkuList } from "@/request/requests";
  import { MessageSuccess,MessageError,MessageInfo,randomNum } from "@/util/util"
  export default {
    name: "user",
    data(){
      const typeOptions=[
          {
              value:"1",
              label:"普通"
          },
          {
              value:"2",
              label:"分月折扣"
          }
      ]
      const columns = [
          {type: 'selection'},
          {prop: 'id',label: '序号',width:50},
          {prop: 'email',label: '用户邮箱'},
          {prop: 'discountcode',label: '折扣码',width:100},
          {prop: 'discountratio',label: '折扣比例'},
          {prop: 'type',label: '折扣类型',formatter: row => this.typeState(row)},
          // {prop: 'rules',label: '折扣规则'},
          {prop: 'weight',label: '权重'},
          {prop: 'operatorid',label: '操作人id'},
          {prop: 'operator',label: '操作人'},
          {prop: 'createTime',label: '创建时间'},
          {prop: 'updateTime',label: '更新时间'},
      ]
      const searchForm = [
          {
            type: 'input',
            id: 'discountcode',
            label: '折扣码',
            el: {placeholder: '请输入折扣码', clearable: true},
          },
          {
              type: 'input',
              id: 'email',
              label: '用户邮箱',
              el: {placeholder: '请输入用户邮箱', clearable: true},
          },
          {
            type: 'select',
            id: 'type',
            label: '折扣类型',
            el: {placeholder: '请输入折扣类型', clearable: true},
            options:typeOptions
          },
          {
            type: 'input',
            id: 'rules',
            label: '折扣规则',
            el: {placeholder: '请输入折扣规则', clearable: true},
          },
          {
            type: 'input',
            id: 'operator',
            label: '操作人',
            el: {placeholder: '请输入操作人', clearable: true},
          },
      ]
      return {
        columns,
        typeOptions,
        userinfo:{},
        goodsList:[],
        skuList:[],
        tableConfig: {
          searchForm,
          url: 'discountcode/list',
          dataPath: 'data.list',
          totalPath: 'data.total',
          paginationSize:10,
          extraButtons: [
            {
              type: 'primary',
              text: '编辑',
              icon: 'el-icon-edit-outline',
              show:()=>{return this.hasRole('discountcode:update')},
              atClick: async row => {
                this.handleEdit(row)
                return false
              },
            },
            {
              type: 'primary',
              text: '删除',
              icon: 'el-icon-delete',
              show:()=>{return this.hasRole('discountcode:delete')},
              atClick: async row => {
                this.handleDelete(row)
                return false
              },
            },
          ],
          headerButtons: [
            {
              text: '新增',
              type: 'primary',
              show:()=>{return this.hasRole('discountcode:insert')},
              atClick: async () => {
                this.handleInsert()
                return false
              },
            },
            {
              text: '批量删除',
              type: 'primary',
              show:()=>{return this.hasRole('discountcode:batchDelete')},
              disabled: selected => selected.length < 1,
              atClick: selected => {
                let ids = selected.map(item => item.id)
                this.Batch('delete', ids)
                return false
              },
            },
          ],
          hasDelete: false,
          hasEdit: false,
          hasOperation: true,
          hasNew: false,
          operationAttrs: {
            width: '150px',
            fixed: 'right',
          },
        },
        form:this.initForm(),
        title:"",
        type:"",
        dialogFormVisible:false,
        formLabelWidth:"100px",
        rules: {
            id :[
              {required: true, message: '请输入序号', trigger: 'blur'},],
            email :[
                {required: true, message: '请输入邮箱', trigger: 'blur'},],
            discountcode :[
              {required: true, message: '请输入折扣码', trigger: 'blur'},],
            discountratio :[
              {required: true, message: '请输入折扣比例 (几折)', trigger: 'blur'},],
            type :[
              {required: true, message: '请输入折扣类型 1:普通  2:分月折扣', trigger: 'blur'},],
            rules :[
              {required: true, message: '请输入折扣规则', trigger: 'blur'},],
            weight :[
                {required: true, message: '请输入权重', trigger: 'blur'},],
            operatorid :[
              {required: true, message: '请输入操作人id', trigger: 'blur'},],
            operator :[
              {required: true, message: '请输入操作人', trigger: 'blur'},],
            createTime :[
              {required: true, message: '请输入创建时间', trigger: 'blur'},],
            updateTime :[
              {required: true, message: '请输入更新时间', trigger: 'blur'},],
            ext1 :[
              {required: true, message: '请输入扩展字段', trigger: 'blur'},],
            ext2 :[
              {required: true, message: '请输入扩展字段', trigger: 'blur'},],
            ext3 :[
              {required: true, message: '请输入扩展字段', trigger: 'blur'},],
            ext4 :[
              {required: true, message: '请输入扩展字段', trigger: 'blur'},],
        },
      }
    },
    methods:{
        //添加表格里的行
        applayaddRow() {
            this.form.rules.push({
                goodsid: "",
                skuid: "",
                discountratio: "",
            });
        },
        //删除表格里的行
        applaydeleteRow(index, rows) {
            rows.splice(index, 1);
        },
      clearRules(){
          this.form.rules=[]
      },
      typeState(row){
          let data
          this.typeOptions.forEach(r=>{
              if (r.value==row.type){
                  data=r.label
              }
          })
          return data
      },
      getRandomNum(){
          if (this.type=='update'){
              this.$confirmel('此操作将覆盖用户原有折扣码, 是否继续?', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
              }).then(() => {
                  this.form.discountcode=randomNum()
              }).catch(() => {
                  MessageInfo("已取消");
              });
          }else {
              this.form.discountcode=randomNum()
          }
      },
      hasRole(val){
        return this.hasPerm(val)
      },
      initForm() {//初始数据
        return {
          id : "", // 序号
          email:"",//用户邮箱
          discountcode : "", // 折扣码
          discountratio : "", // 折扣比例 (几折)
          type : "", // 折扣类型 1:普通  2:分月折扣
          rules : "", // 折扣规则
          weight:"", //权重
          operatorid : "", // 操作人id
          operator : "", // 操作人
          createTime : "", // 创建时间
          updateTime : "", // 更新时间
          ext1 : "", // 扩展字段
          ext2 : "", // 扩展字段
          ext3 : "", // 扩展字段
          ext4 : "", // 扩展字段
        }
      },
      handleEdit(row) {
        console.log(row)
        this.form = row
        if (null==this.form.rules||""==this.form.rules){
            this.form.rules=[]
        }else {
            this.form.rules=JSON.parse(this.form.rules)
            let arr = new Set(this.form.rules.map(item=>item.goodsid));
            arr.forEach(r=>{
                this.getGoodsSkuListInfo(r)
            })
        }
        this.openDialog();
        this.title = "修改信息"
        this.type = "update"
      },
      handleDelete(row) {
        this.$confirmel('此操作将永久删除该文件, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            this.operatorDeleteUser(row)
        }).catch(() => {
            MessageInfo("已取消删除");
        });
      },
      async operatorDeleteUser(row) {
        let res = await deleteDiscountcode(row.id)
        if (res.status == "200") {
          MessageSuccess(res.msg)
        } else {
          MessageError(res.msg)
        }
        this.$refs.table.getList()
      },
      handleInsert() {
        this.openDialog();
        this.title = "新增"
        this.type = "insert"
      },
      async Batch(type, ids) {
        if (type == "delete") {
          let res = await bacthDelDiscountcode(ids)
          if (res.status == "200") {
            MessageSuccess(res.msg)
          } else {
            MessageError(res.msg)
          }
        }
        this.$refs.table.getList()
      },
      openDialog() {
        this.dialogFormVisible = true
      },
      closeDialog() {
        this.dialogFormVisible = false
        this.form = this.initForm();
        this.$refs.ruleUser.clearValidate();
        this.$refs.table.getList()
      },
      async submitUser() {
        this.$refs.ruleUser.validate((valid) => {
          if (valid) {
            this.operatorUser()
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      async operatorUser() {
        let res;
        if (this.form.type=='1'){
            this.form.rules=''
        }else {
            for (let i = 0; i < this.form.rules.length; i++) {
                if (this.form.rules[i].goodsid==""||this.form.rules[i].skuid==""){
                    MessageError("折扣规则中所有数据不能为空！")
                    return
                }
                for (let j = 0; j < this.form.rules.length; j++) {
                    if (this.form.rules[i].goodsid==this.form.rules[j].goodsid&&this.form.rules[i].skuid==this.form.rules[j].skuid&&i!=j){
                        MessageError("折扣规则中有相同商品相同套餐的数据！")
                        return
                    }
                }
            }
            this.form.rules=JSON.stringify(this.form.rules)
        }
        if (this.type == "insert") {
          res = await insertDiscountcode(this.form)
          if (res.status == "200") {
            MessageSuccess(res.msg)
            this.closeDialog()
          } else {
            MessageError(res.msg)
          }
        } else if (this.type == "update") {
          res = await updateDiscountcode(this.form)
          if (res.status == "200") {
            MessageSuccess(res.msg)
            this.closeDialog()
          } else {
            MessageError(res.msg)
          }
        }
        this.$refs.table.getList()
      },getUserInfo(){
        let deadline = sessionStorage.getItem('deadline');
        let userInfo = sessionStorage.getItem("userInfo");
        let token = sessionStorage.getItem('token');
        if(!deadline||!userInfo||!token) {
          this.removeInfo()
          MessageError("请先登录！")
          this.$router.push({ path: "/login" });
          return false
        }else{
          let now = new Date().getTime();
          if (deadline<now){
            this.removeInfo()
            MessageError("请先登录！")
            this.$router.push({ path: "/login" });
            return false
          }
        }
        return userInfo;
      },
      removeInfo(){
        sessionStorage.removeItem("userInfo");
        sessionStorage.removeItem("deadline");
        sessionStorage.removeItem("token");
      },
      async getGoodsSpuListInfo() {
            let res = await getGoodsSpuList()
            if (res.status == "200") {
                this.goodsList = res.data.list
            } else {
                MessageError(res.msg)
            }
      },
      async getGoodsSkuListInfo(goodsid) {
            let res = await getGoodsSkuList(goodsid)
            if (res.status == "200") {
                this.skuList=res.data.list
            } else {
                MessageError(res.msg)
            }
      }
    },
    created() {
      let userInfo=this.getUserInfo()
      if (userInfo){
        this.userinfo=JSON.parse(userInfo)
        this.tableConfig.axiosConfig={
          headers: {
            'Authorization': 'X-KILOGOD-AUTHORIZATION '+sessionStorage.getItem('token'),

          }
        }
        if (!this.hasRole('discountcode:update')&&!this.hasRole('discountcode:delete')){
          this.tableConfig.hasOperation=false
        }
        this.getGoodsSpuListInfo()
      }
    }
  }
</script>

<style scoped>

</style>
